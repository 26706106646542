import { handleErrorWithSentry, BrowserTracing } from "@sentry/sveltekit";
import { RewriteFrames } from "@sentry/integrations";
import cfg from "$lib/config/config";
import * as Sentry from "@sentry/sveltekit";
import { version, dev } from "$app/environment";

export async function initSentry() {
  Sentry.init({
    dsn: `https://${cfg.SENTRY_DSN}.ingest.sentry.io/${cfg.SENTRY_PROJECT_ID}`,
    tracesSampleRate: cfg.SENTRY_TRACE_SAMPLERATE,
    release: "belstaff-frontend@" + `v${version}`,
    environment: cfg.SENTRY_ENV,
    allowUrls: [/https?:\/\/www\.belstaff\.com/],
    integrations: [
      new BrowserTracing(),
      new RewriteFrames({
        root: cfg.BASE_PATH,
        prefix: `${cfg.BASE_PATH}/_app/immutable/`, // used to inject the region id into Sentry sourcemaps.
      }),
    ],
  });
}

// Delay initialisation on the dev server to avoid a race condition.
// See https://github.com/getsentry/sentry-javascript/issues/9252
setTimeout(
  () => {
    initSentry();
  },
  dev ? 10 : 0,
);

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
