import type { ParamMatcher } from "@sveltejs/kit";
import cfg from "$lib/config/config";

let langs = cfg.LANGS || [];
if (cfg.DEFAULT_LOCALE && cfg.LANGS) {
  // filter default locales from langs (DE/UK) for example.
  langs = cfg.LANGS.filter(lang => lang !== cfg.DEFAULT_LOCALE);
}

const validLangRegex = new RegExp(`^(${langs.join("|")})$`);

export const match: ParamMatcher = param => {
  // Check if lang is a two character string and matches one of our languages.
  return validLangRegex.test(param);
};
